import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm;
  errorMessage: Boolean = true
  submitted = false;
  passwordSubmitted = false;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService,
    public modalService: NgbModal,
    public location: Location
  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({
      username: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])]
    });


    this.loginForm.valueChanges
      .subscribe(data => {

      });


  }
  get c() { return this.loginForm.controls; }
  ngOnInit() {
  }

  checkLogin() {
    // debugger
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    if (login == 'true') {
      this.router.navigateByUrl(previousURL)
    }
  }


  login(formData) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return
    }
    let apiUrl = this.apiConfig.login;
    let body = JSON.stringify({ "LoginForm": { "username": formData.username, "password": formData.password } });
    this.apiService.post(apiUrl, body)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.errorMessage = true;
          let body = res.body;
          this.toast.Success('Login successful');
          localStorage.setItem("user_name", (res.body.data.user_name));
          localStorage.setItem("user_id", (res.body.data.user_role));
          localStorage.setItem("id", (res.body.data.id));
          window.localStorage.setItem("program", JSON.stringify(res.body.data));
          this.auth.saveAuth(body.data.access_token);
          this.submitted = false;

          if (res.body.data.user_role == 1) {
            this.router.navigate(["hubAdmin"]);
          } else if (res.body.data.user_role == 2) {
            this.router.navigate(["hubAdmin"]);
          }
          else if (res.body.data.user_role == 3) {
            this.router.navigate(["hubAdmin"]);
          }



        }
      }, err => {
        this.errorMessage = false;
        // 
      })
  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }


}


